<template>
    <div class="pedido">
        <v-container v-if="loaded">
            <v-row>
                <v-col>
                    <h1>¡Gracias por tu compra!</h1>
                    <p>Hola {{pedido.nombre}}, estamos preparando tu pedido. Te avisaremos nada más salga de nuestros almacenes y te enviaremos un email de seguimiento para que puedas saber dónde se encuentra en cada momento.</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span class="bold">Id de Pedido:</span> {{pedido._id}}
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div>
                        <span class="bold">Nombre: </span> {{pedido.nombre}} {{pedido.apellido}}
                    </div>

                    <div>
                        <span class="bold">Corre: </span> {{pedido.email}}
                    </div>

                    <div>
                        <span class="bold">Teléfono: </span> {{pedido.telefono}}
                    </div>

                    <div>
                        <span class="bold">Dirección de entrega: </span> {{pedido.calle}} {{pedido.numero}} {{pedido.colonia}} Código Postal: {{pedido.codigoPostal}}, {{pedido.ciudad}}, {{pedido.estado}}
                    </div>

                    <div>
                        <span class="bold">Medio de pago: </span> {{pedido.pagadoCon}}
                    </div>
                </v-col>
            </v-row>
            
            <v-row align="center" justify="center">
                <v-col cols="10" sm="6">
                    <v-simple-table>
                        <template v-slot:default>
                        <tbody>
                            <tr>
                            <td class="text-left">
                                <span class="bold">Producto</span>
                            </td>
                            <td class="text-left">
                                <span class="bold">Cantidad</span>
                            </td>
                            <td class="text-left">
                                <span class="bold">Precio</span>
                            </td>
                            </tr>
                            <tr v-for="(item, i) in pedido.items" :key="'item-'+i">
                            <td>
                                {{ item.value.nombreProducto }}
                            </td>
                            <td>{{ item.value.cantidad }}</td>
                            <td>${{ numberWithCommas(item.value.precio) }}</td>
                            </tr>
                            <tr>
                            <td class="text-right" colspan="2">
                                <span class="bold">Subtotal</span>
                            </td>
                            <td>
                                <span>${{ numberWithCommas(pedido.subtotal) }}</span>
                            </td>
                            </tr>
                            <tr>
                            <td class="text-right" colspan="2">
                                <span class="bold">Envío</span>
                            </td>
                            <td>
                                <span>${{ numberWithCommas(pedido.envio) }}</span>
                            </td>
                            </tr>
                            <tr>
                            <td class="text-right" colspan="2">
                                <span class="bold">Total</span>
                            </td>
                            <td>
                                <span>${{ numberWithCommas(pedido.total) }}</span>
                            </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data () {
        return {
            pedido: {},
            loaded: false
        }
    },
    beforeMount: function () {
        fetch('https://content.elmurcielagotequileria.com/api/collections/get/Pedidos', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {_id: this.$route.params.id},
                populate: 1, // resolve linked collection items
            })
        })
        .then(res=>res.json())
        .then(res => this.pedido = res.entries[0])
        .then(this.loaded = true);
    },
    methods: {
        numberWithCommas: function (x) {
          return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    }
}
</script>